import React from "react";
import { HashLink } from 'react-router-hash-link';
function Footer() {
  return (
    <footer id="footer" className="footer accent-background">

      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-about">
            <a href="index.html" className="logo d-flex align-items-center">
              <span className="sitename">Anantam</span>
            </a>
            <p>We invite you to explore how Anantam IT Solutions, along with our trusted partners, can be your one-stop IT
              solution provider.</p>
            <div className="social-links d-flex mt-4">
              <a target="_blank" href="https://www.linkedin.com/company/anantam-it-solutions/"><i
                className="bi bi-linkedin"></i></a>
              <a target="_blank" href="https://www.instagram.com/anantam_it"><i className="bi bi-instagram"></i></a>
              <a target="_blank" href=""><i className="bi bi-facebook"></i></a>
            </div>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="about-us">About us</a></li>
              <li><a><HashLink smooth to='/#services' > Services </HashLink></a></li>
              {/* <li>Terms of service</li> */}
              <li><a href="privacy-policy">Privacy policy</a></li>
            </ul>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><a href="web-app-service">Web App Development</a></li>
              <li><a href="service-detail">Mobile App Development</a></li>
              <li><a href="software-prod-eng">Software Product Engineering</a></li>
              <li><a href="ui-ux-service">UI/UX & Graphic designing</a></li>
              <li><a href="seo-digitalmarketing">SEO & Digital Marketing</a></li>
              <li><a href="quality-analysis-testing">Quality Anaysis & Testing</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>413 Harmony Icon,Thaltej</p>
            <p>Ahmedabad, Gujarat 380059</p>
            <p>India</p>
            <p className="mt-4"><strong>Phone:</strong> <span>+91 972727 0155</span></p>
            <p><strong>Email:</strong> <span>hello@anantamit.com</span></p>
          </div>

        </div>
      </div>

      <div className="container copyright text-center mt-4">
        <p>© <span>Copyright</span> <strong className="px-1 sitename">Anantam</strong> <span>All Rights Reserved</span></p>
      </div>

    </footer>
  );
}
export default Footer;